import { useState } from "react";
import Selector from "../components/UiComponents/Selector";
import ViewHeader from "../components/UiComponents/ViewHeader";
import LiveInsights from "../components/insights/live/LiveInsights";
import HeatmapView from "./HeatmapView";
import AutomationInsights from "../components/insights/automation/AutomationInsights";
import { InsightsContainer } from "../components/insights/insightsStyles";
import DecibelInsights from "../components/insights/decibel/DecibelInsights";
import { DateTime } from "luxon";
import InsightsDatePicker from "../components/insights/InsightsDatePicker";
import trendTypes from "../components/statistics/trendTypes";

const tabKeys = {
    DECIBEL: 'DECIBEL',
    LIVE: 'LIVE',
    AUTOMATION: 'AUTOMATION',
    MAP: 'MAP',
}
const tabs = [
    {
        display: 'Automation',
        key: tabKeys.AUTOMATION
    },
    {
        display: 'Decibel',
        key: tabKeys.DECIBEL
    },
    {
        display: 'Live',
        key: tabKeys.LIVE
    },
    {
        display: 'Map',
        key: tabKeys.MAP
    }
]

const InsightsView = props => {
    const [selectedTab, setSelectedTab] = useState(tabs[0]);
    const [selectedDateTime, setSelectedDateTime] = useState(DateTime.fromObject({}, { zone: props.customer?.timezone ?? 'Europe/Oslo' }).startOf('day'));
    const [trendPeriod, setTrendPeriod] = useState(trendTypes.periods.DAY);

    const handleTabSelection = (item) => {
        if (item?.key === tabKeys.DECIBEL) {
            setTrendPeriod(trendTypes.periods.DAY);
        }
        setSelectedTab(prev => {
            if (prev?.key !== tabKeys.DECIBEL && prev?.key !== tabKeys.AUTOMATION) {
                setSelectedDateTime(DateTime.fromObject({}, { zone: props.customer?.timezone ?? 'Europe/Oslo' }).startOf('day'));
            }
            return item;
        })
    }

    return <>
        <ViewHeader headerText={`Insights`} showVenueName={true} />

        <InsightsContainer>
            <Selector
                large
                items={tabs}
                selectedItem={selectedTab}
                onClick={(item) => handleTabSelection(item)}
            />

            {selectedTab?.key === tabKeys.DECIBEL || selectedTab?.key === tabKeys.AUTOMATION ? <>
                <InsightsDatePicker
                    dateTime={selectedDateTime}
                    setDateTime={setSelectedDateTime}
                    showPeriod={selectedTab?.key === tabKeys.AUTOMATION}
                    period={trendPeriod}
                    setPeriod={setTrendPeriod}
                    timezone={props?.customer?.timezone ?? 'Europe/Oslo'} />
            </> : <></>}

            {selectedTab?.key === tabKeys.DECIBEL ? <>
                <DecibelInsights
                    customer={props.customer}
                    dateTime={selectedDateTime}
                />
            </> : <></>}

            {selectedTab?.key === tabKeys.AUTOMATION ? <>
                <AutomationInsights
                    customer={props.customer}
                    dateTime={selectedDateTime}
                    trendPeriod={trendPeriod}
                />
            </> : <></>}

            {selectedTab?.key === tabKeys.LIVE ? <>
                <LiveInsights />
            </> : <></>}

            {selectedTab?.key === tabKeys.MAP ? <>
                <HeatmapView customerId={props.customer?.customerId} hideBackButton />
            </> : <></>}
        </InsightsContainer>


    </>
}

export default InsightsView;