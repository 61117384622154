import styled from "styled-components";
import theme from "../../UI/theme";

export const SettingsContainer = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 20px;
    padding-bottom: 20px;
`

export const SettingsSection = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 2px;
`

export const SettingsSubHeader = styled.label`
    font-size: ${theme.fontSize.large};
    padding: 10px;
    display: flex;
    align-items: center;
`

export const SettingsParagraph = styled.label`
    font-size: ${theme.fontSize.small};
    color: ${theme.colors.textGray};
`

export const SettingsParagraphContainer = styled.div`
    border: 2px solid ${theme.colors.darkSpace90};
    padding: 10px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
`

export const SettingsLink = styled.div`
    --iconSize: 50px;

    background-color: ${theme.colors.darkSpace90};
    color: ${theme.colors.textGray};
    height: var(--iconSize);
    display: grid;
    grid-template-columns: 1fr var(--iconSize);
    grid-gap: 10px;
    align-items: center;
    padding: 0 10px;
    border-radius: 2px;

    > svg {
        justify-self: center;
    }

    > label {
        cursor: inherit;
    }

    @media (hover:hover) {
        &:hover {
            cursor: pointer;
            background-color: ${theme.colors.darkSpace80};
        }
    }
`