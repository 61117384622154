import styled from "styled-components";
import Selector from "../../UiComponents/Selector";
import LiveChartContainer from "./LiveChartContainer";
import { useState } from "react";
import zoneTypes from "../../live/zone/zoneTypes";

const ChartContainer = styled.div`
    width: 100%;
    height: 100%;
    min-height: 200px;
`
const dataIntervals = [
    { key: zoneTypes.intervals.LAST_15_MINUTES, display: '15 minutes' },
    { key: zoneTypes.intervals.LAST_4_HOURS, display: '4 hours' },
    { key: zoneTypes.intervals.LAST_24_HOURS, display: '24 hours' }
];

const LiveChartWithIntervalSelector = props => {
    const [dataInterval, setDataInterval] = useState(dataIntervals[0]);

    return <>
        <ChartContainer>
            <LiveChartContainer
                zone={props.zone}
                zoneLive={props.zoneLive}
                processorConnected={props.processorConnected}
                dataInterval={dataInterval?.key}
                timezone={props.timezone || null}
                detailed />
        </ChartContainer>

        <Selector
            items={dataIntervals}
            selectedItem={dataInterval}
            onClick={(item) => setDataInterval(item)} />
    </>
}

export default LiveChartWithIntervalSelector;