import { useState, useEffect } from "react";
import { usePageVisibility } from "react-page-visibility";
import { getDateTime, getDefinedLogInterval } from "../../../scripts/log";
import styled from "styled-components";
import zoneTypes from "../../live/zone/zoneTypes";
import theme from "../../../UI/theme";
import LiveChart from "./LiveChart";
import { DateTime } from "luxon";
import { isNumber } from "../../../scripts/common";

const Container = styled.div`
    height: 100%;
    width: 100%;

    canvas {
        width: 100%;
        max-width: 100%;
        max-height: 100%;
    }
`

const ErrorLabel = styled.label`
    color: ${theme.colors.textGray};
    font-size: 0.65rem;
    font-weight: 100;
    display: flex;
    text-align: center;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
`

const LiveChartContainer = props => {
    const [graphData, setGraphData] = useState([]);
    const isVisible = usePageVisibility();

    useEffect(() => {
        if (isVisible === true) {
            getData();
        }
    }, [isVisible, props.dataInterval]);

    useEffect(() => {
        setGraphData(prev => {
            const prevCopy = JSON.parse(JSON.stringify(prev));
            prevCopy.pop();
            prevCopy.unshift(formatData(
                { 
                    time: props.zoneLive?.time, 
                    averageDecibel: props.zoneLive?.averageDecibel, 
                    noise: props.zoneLive?.noise, 
                    sysvol: props.zoneLive?.sysvol,
                    meterDb: props.zoneLive?.meterDb
                },
                props.timezone));
            return prevCopy;
        });
    }, [props.zoneLive?.time]);

    const getData = async () => {
        const newData = await getDefinedLogInterval(props.zone?.customerId, props.zone?.zoneId, props.dataInterval ?? zoneTypes.intervals.LAST_15_MINUTES, null);
        setGraphData(newData.map(dataPoint => formatData(dataPoint, props.timezone)));
    }

    return <Container className={props.className} disabled={!props.processorConnected}>
        {props.processorConnected ?
            <LiveChart 
                data={graphData} 
                detailed={props.detailed} 
                showSignalLevel={props.zone.meterId}
            /> :
            <ErrorLabel>
                Processor not connected
            </ErrorLabel>}
    </Container>
}

export default LiveChartContainer;


const formatData = (dataPoint, timeZone=false) => {
    return {
        ...dataPoint,
        timestamp: DateTime.fromJSDate(getDateTime(dataPoint.time, timeZone)),        
        meterDb: isNumber(dataPoint.meterDb) ? dataPoint.meterDb + 80 : null
    }
}