import { useState } from "react";
import Radio from "../../../UiComponents/Radio";
import { entityTypes } from "../../../permission/permissions";
import { bundleKeys, findUncommonPermissions, getPermissionsFromBundle, getUserDisplayName, groupPermissions, permissionTableTypes } from "./userAdministrationUtils"
import { HeaderRow, Placeholder, StyledTable } from "../../../installation/table/styles/TableStyles";
import { TableCell } from "../../../installation/fields/Styles";
import CustomPermissionsTable from "./CustomPermissionsTable";
import PermissionsTableHeader from "./PermissionsTableHeader";
import { addPermission, deletePermission } from "../../../../scripts/permission";
import { TableContainer, TableLink } from "./styles";
import DeletePermissionButton from "./DeletePermissionButton";
import theme from "../../../../UI/theme";
import DeleteSharedAccountButton from "./DeleteSharedAccountButton";


export const bundleColumns = [{
    value: bundleKeys.basic,
    display: 'Basic',
    description: 'Panel and Insights'
}, {
    value: bundleKeys.readOnly,
    display: 'Basic Read',
    description: 'Basic, but read-only'
}, {
    value: bundleKeys.admin,
    display: 'Venue Admin',
    description: 'Settings and User Administration'
}, {
    value: bundleKeys.techAdmin,
    display: 'Installer',
    description: 'Installation and Calibration'
}, {
    value: bundleKeys.custom,
    display: 'Custom',
    description: 'Click "detailed" to view'
}]

const PermissionsTable = ({ tableType, venues, users, originalPermissions, refresh, permissionTableRef, setViewState, refreshUsers }) => {
    const [permissions, setPermissions] = useState(originalPermissions);
    const [showAdvanced, setShowAdvanced] = useState(false);
    const permissionObjects = groupPermissions(venues, users, permissions);

    const isEdited = () => {
        const addedPermissions = findUncommonPermissions(permissions, originalPermissions);
        const deletedPermissions = findUncommonPermissions(originalPermissions, permissions);
        const edited = addedPermissions.length + deletedPermissions.length > 0;
        if (permissionTableRef) {
            permissionTableRef.current.isEdited = edited;
        }
        return edited;
    }

    const savePermissions = async () => {
        try {
            const addedPermissions = findUncommonPermissions(permissions, originalPermissions);
            const deletedPermissions = findUncommonPermissions(originalPermissions, permissions);
            for (let addedPermission of addedPermissions) {
                await addPermission(addedPermission);
            }
            for (let deletedPermission of deletedPermissions) {
                await deletePermission(deletedPermission);
            }
            await refresh();
        } catch (err) {
            console.log('Failed to save permissions', err);
        }
    }

    const onBundleClick = (permissionObject, bundleKey) => {
        if (bundleKey === bundleKeys.custom) {
            return;
        }
        const permissionTypes = getPermissionsFromBundle(bundleKey);
        const updatedUserPermissions = permissionTypes.map(permissionType => ({
            userId: permissionObject.userId,
            permissionType,
            entityType: entityTypes.venue,
            entityId: permissionObject.venueId.toString()
        }))

        let updatedPermissions = permissions.filter(permission => !(permission.entityId === permissionObject.venueId.toString() && permission.userId === permissionObject.userId))
        updatedPermissions = [...updatedPermissions.map(p => ({ ...p })), ...updatedUserPermissions];
        setPermissions(updatedPermissions);
    }

    const addPermissionBundle = async (user, venues, bundleKey) => {
        const permissionTypes = getPermissionsFromBundle(bundleKey);
        const addedPermissions = [];
        for (let venue of venues) {
            for (let permissionType of permissionTypes) {
                const permission = {
                    userId: user.userId,
                    permissionType,
                    entityType: entityTypes.venue,
                    entityId: venue.customerId.toString()
                }
                await addPermission(permission);
                addedPermissions.push(permission);
            }
        }
        await refreshUsers();
        await refresh();
        setPermissions([...permissions, ...addedPermissions]);
    }

    const refreshWithUpdate = async () => {
        await refreshUsers();
        const permissionsRes = await refresh();
        setPermissions(permissionsRes);
    }

    const removePermissionObject = async (permissionObject) => {
        const permissionMatch = (permission) => permission.entityId === permissionObject.venueId.toString() && permission.userId === permissionObject.userId;
        const deletedPermissions = permissions.filter(permissionMatch);
        for (let deletedPermission of deletedPermissions) {
            await deletePermission(deletedPermission);
        }
        await refreshUsers();
        await refresh();
        let updatedPermissions = permissions.filter(permission => !(permissionMatch(permission)))
        setPermissions(updatedPermissions);
    }

    const onNameClick = (permissionObject) => {
        if (tableType === permissionTableTypes.user) {
            setViewState(current => ({ ...current, view: 'venue', venueId: permissionObject.customerId }))
        } else if (tableType === permissionTableTypes.venue) {
            setViewState(current => ({ ...current, view: 'user', userId: permissionObject.userId }))
        }
    }

    return <TableContainer>
        <PermissionsTableHeader
            tableType={tableType}
            users={users}
            venues={venues}
            savePermissions={savePermissions}
            showAdvanced={showAdvanced}
            setShowAdvanced={setShowAdvanced}
            isEdited={isEdited}
            addPermissionBundle={addPermissionBundle}
            permissions={permissions}
            refreshWithUpdate={refreshWithUpdate}
        />

        {permissionObjects.length ? <>
            {!showAdvanced ? <>
                <StyledTable>
                    <thead>
                        <HeaderRow>
                            <th style={{ width: '40%' }}>{tableType === permissionTableTypes.venue ? 'User' : 'Venue'}</th>
                            {bundleColumns.map(col => <th key={col.value} style={{ width: `${60 / bundleColumns.length}%`, textAlign: 'center', color: theme.colors.darkSpace20 }}>
                                {col.display}<br />
                                <span style={{ fontSize: theme.fontSize.tiny, color: theme.colors.darkSpace50 }}>{col.description}</span>
                            </th>)}
                            <th></th>
                        </HeaderRow>
                    </thead>
                    <tbody>
                        {permissionObjects.map((permissionObject, i) => <tr key={i}>
                            <TableCell>
                                <TableLink onClick={() => onNameClick(permissionObject)}>
                                    {tableType === permissionTableTypes.venue ? getUserDisplayName(permissionObject) : permissionObject.companyName}
                                </TableLink>
                            </TableCell>
                            {bundleColumns.map(col => <TableCell key={col.value}>
                                <Radio
                                    centered={true}
                                    checked={permissionObject.bundle === col.value}
                                    onChange={() => onBundleClick(permissionObject, col.value)}
                                    disabled={col.value === bundleKeys.custom}
                                />
                            </TableCell>)}
                            <TableCell>
                                {permissionObject?.shared === 1 ? <>
                                    <DeleteSharedAccountButton
                                        userId={permissionObject?.userId}
                                        customerId={permissionObject?.customerId}
                                        userDisplayName={permissionObject.userDisplayName}
                                        refreshWithUpdate={refreshWithUpdate} />
                                </> :
                                    <DeletePermissionButton removePermissionObject={() => removePermissionObject(permissionObject)} />
                                }
                            </TableCell>
                        </tr>)}
                    </tbody>
                </StyledTable>
            </> : <>
                <CustomPermissionsTable
                    tableType={tableType}
                    permissionObjects={permissionObjects}
                    permissions={permissions}
                    setPermissions={setPermissions}
                />
            </>}
        </> : <>
            <div>
                <Placeholder>
                    {tableType === permissionTableTypes.venue ? <>
                        <p>No users have been added</p>
                    </> : <>
                        <p>No venues have been added</p>
                    </>}
                </Placeholder>

            </div>
        </>}
    </TableContainer>
}

export default PermissionsTable;

