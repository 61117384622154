import React, { useState } from 'react';
import styled from 'styled-components';
import { postEvent } from '../../../../../scripts/event';
import Modal from '../../../../UiComponents/Modal';
import HubChoiceField from '../../../fields/HubChoiceField';
import { cellStates } from '../../../table/tableTypes';
import Button from '../../../../UiComponents/Button';
import { ButtonRowContainer, ModalContainer, ModalHeader, ModalText } from '../../../../panel/ModalStyles';


const SECONDS = 1000;
const MINUTES = 60 * SECONDS;
const NETWORK_INTERVAL_TIME = 2 * MINUTES + 50 * SECONDS;
const MINIMUM_OPEN_TIME = 10 * MINUTES; 

const OpenNetworkButton = ({ hubs }) => {
    const [showModal, setShowModal] = useState(false);
    const [selectedHub, setSelectedHub] = useState(hubs?.[0]);

    function addSensors() {
        if (selectedHub) {
            postEvent(selectedHub.hubId, selectedHub.customerId, 'ADD_SENSORS');
            setShowModal(false);

            return; // remove this line to send multiple events to keep the network open

            const hubId = selectedHub.hubId;
            const customerId = selectedHub.customerId;

            const networkInterval = setInterval(() => {
                console.log('Adding sensors');
                postEvent(hubId, customerId, 'ADD_SENSORS');
            }, NETWORK_INTERVAL_TIME);

            setTimeout(() => {
                clearInterval(networkInterval)
            }, MINIMUM_OPEN_TIME);
        }
    }

    return <>
        <Button small secondary onClick={() => setShowModal(true)}>
            Open network
        </Button>
        <Modal show={showModal} close={() => setShowModal(false)}>
            <ModalContainer>
                <ModalHeader>Open network</ModalHeader>
                <ModalText>This action will open the hub for new connections and add devices on the network.</ModalText>
                {hubs.length !== 1 ? <>
                    <ModalText>Select the hub that you want to open the network on.</ModalText>
                    <HubChoiceField
                        hubId={selectedHub?.hubId}
                        hubs={hubs}
                        updateObject={({ hubId }) => setSelectedHub(hubs.find(hub => hub.hubId === hubId))}
                        cellControl={{ cellState: cellStates.FORM }}
                    />
                </> : <></>}
                <ButtonRowContainer>
                    <Button primary disabled={!selectedHub} onClick={() => addSensors()}>Open network</Button>
                    <Button secondary onClick={() => setShowModal(false)}>Cancel</Button>
                </ButtonRowContainer>
            </ModalContainer>
        </Modal>
    </>
}


export default OpenNetworkButton;