import { DateTime } from "luxon";
import { FaChevronRight, FaSeedling, FaVolumeMute, FaVolumeUp } from "react-icons/fa";
import styled from "styled-components";
import { getDayOfWeek } from "../../scripts/common";
import theme from "../../UI/theme";
import scheduleTypes from "../settings/venue/schedule/scheduleTypes";
import WaWaved from "../UiComponents/Icons/WaWaved";
import VibeChangeIcon from "../UiComponents/VibeChangeIcon";
import { ModalLink, ModalText, RowContainer } from "./ModalStyles";
import { hasVenuePermission, permissionTypes } from "../permission/permissions";
import { useContext } from "react";
import UserContext from "../../hooks/UserContext";

const ScheduleTime = styled.span`
    background-color: ${theme.colors.darkSpace80};
    color: ${theme.colors.greenEnergy};
    padding: 2px 5px;
    border-radius: 5px;
    margin-right: 5px;
`

const IconContainer = styled.div`
    display: flex;
    align-items: center;
    > *:not(:last-child) {
        margin-right: 5px;
    }
`

const ScheduleRow = props => {
    return <RowContainer>
        <ModalText>
            <ScheduleTime>
                {props.schedule?.time?.substring(0, 2)}:{props.schedule?.time?.substring(2, 4)}
            </ScheduleTime>
            {props?.schedule?.displayName}
        </ModalText>
        <ModalText>
            <IconContainer>
                {props?.schedule?.scheduleTypes?.map((type, index) => {
                    switch (type) {
                        case scheduleTypes.types.VIBE:
                            return <VibeChangeIcon
                                key={index}
                                vibeChange={props.schedule?.vibeChange}
                                color={theme.colors.greenEnergy}
                            />
                        case scheduleTypes.types.REGULATION:
                            switch (props.schedule.isRegulating) {
                                case 0:
                                case 1:
                                    return <WaWaved
                                        key={index}
                                        width={'20px'}
                                        stroke={props.schedule.isRegulating ?
                                            theme.colors.greenEnergy :
                                            theme.colors.darkSpace70}
                                    />
                                default:
                                    return <></>
                            }
                        case scheduleTypes.types.MUTE:
                            switch (props.schedule.mute) {
                                case 0:
                                    return <FaVolumeUp key={index} color={theme.colors.greenEnergy} />
                                case 1:
                                    return <FaVolumeMute key={index} color={theme.colors.darkSpace70} />
                                default:
                                    return <></>
                            }
                        case scheduleTypes.types.MOOD_SUSTAIN:
                            return <FaSeedling key={index} color={props.schedule.moodSustain ? theme.colors.greenEnergy : theme.colors.darkSpace70} />
                        default:
                            return <></>
                    }
                })}
            </IconContainer>
        </ModalText>
    </RowContainer>
}
const SchedulesInfo = props => {


    const userContext = useContext(UserContext);

    const dayOfWeek = getDayOfWeek(DateTime.local().toJSDate());
    const tomorrowDayOfWeek = getDayOfWeek(DateTime.local().plus({ days: 1 }).toJSDate());
    const schedulesToday = props.schedules?.
        filter(schedule =>
            schedule.days?.includes(dayOfWeek) &&
            schedule.time >= DateTime.local().toFormat("HHmm"))?.
        sort((a, b) => a.time > b.time ? 1 : -1);
    const schedulesTomorrow = props.schedules?.
        filter(schedule =>
            schedule.days?.includes(tomorrowDayOfWeek) &&
            schedule?.time <= "0500")?.
        sort((a, b) => a.time > b.time ? 1 : -1);
    const allSchedules = [...schedulesToday, ...schedulesTomorrow];

    return <>
        <label>Upcoming schedules</label>

        {allSchedules?.length > 0 ? <>
            {allSchedules?.map((schedule, index) => {
                return <ScheduleRow key={index} schedule={schedule} />
            })}
        </> : <>
            <ModalText>No upcoming schedules</ModalText>
        </>}

        {hasVenuePermission(userContext, permissionTypes.settingsWrite) ?
            <ModalLink to={`/settings/schedules`}>
                <ModalText>Edit schedules</ModalText>
                <FaChevronRight />
            </ModalLink>
        : null }
    </>
}

export default SchedulesInfo;

