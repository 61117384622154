import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import theme from '../UI/theme';
import Button from '../components/UiComponents/Button';
import HubBluetoothConnection, { UUID } from '../components/bluetoothSetup/HubBluetoothConnection';
import WifiSetup from '../components/bluetoothSetup/WifiSetup';
import { SetupContainer, SetupStatusContainer, AnimationOverlay } from '../components/bluetoothSetup/SetupStepContainers';
import ClientSetup from '../components/bluetoothSetup/ClientSetup';
import EthernetSetup from '../components/bluetoothSetup/EthernetSetup';
import InstallerSetup from '../components/bluetoothSetup/InstallerSetup';
import HubSetupComplete from '../components/bluetoothSetup/HubSetupComplete';
import IconLogo from '../components/UiComponents/IconLogo';
import LostBtConnectionModal from '../components/bluetoothSetup/LostBtConnectionModal';
import UserSetup from '../components/bluetoothSetup/UserSetup';

const DocumentationLinkContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: auto;
    a {
        color: ${theme.colors.greenEnergy};
        font-size: 1.1rem;
        text-decoration: none;
        &:hover {
            text-decoration: underline;
        }
    }
`

const SETUP_STEP = {
    INITIAL: 'INITIAL',
    CONNECTING_TO_HUB: 'CONNECTING_TO_HUB',
    WIFI: 'WIFI',
    CLIENT: 'CLIENT',
    INSTALLER: 'INSTALLER',
    USER: 'USER',
    ETHERNET: 'ETHERNET',
    COMPLETE: 'COMPLETE',
}

var hubBluetoothConnection = null;

var defaultWifi = {
    ssid: null,
    ip: null,
    internet: false,
}

var defaultEthernet = {
    ip: null,
    internet: false,
    server: false,
}

const NewInstalationView = (props) => {

    const [setupNewClient, setSetupNewClient] = useState(null);
    const [scanFailed, setScanFailed] = useState(false);
    const [hubConnected, setHubConnected] = useState(false);
    const [setupStep, setSetupStep] = useState(SETUP_STEP.INITIAL);
    const [showLostConnectionModal, setShowLostConnectionModal] = useState(false);
    const [bluetoothCanceled, setBluetoothCanceled] = useState(false);
    const [client, setClient] = useState(null);
    const [wifi, setWifi] = useState(defaultWifi);
    const [ethernet, setEthernet] = useState(defaultEthernet);
    const [internetEnsured, setInternetEnsured] = useState(false);

    useEffect(() => {
        
    }, [setupStep])

    useEffect(() => {
        if (wifi.internet || ethernet.internet) {
            setInternetEnsured(true);
        }
    }, [wifi, ethernet])

    async function startHubConnection(event, reconnecting=false) {
        setShowLostConnectionModal(false)
        var nextSetupStep = SETUP_STEP.ETHERNET;
        if (reconnecting) nextSetupStep = setupStep;
        try{
            const device = await navigator.bluetooth.requestDevice({
                filters: [
                    {
                      namePrefix: "WavedHub",
                    },
                ],
                optionalServices: [UUID.SERVICE]
            })
            setSetupStep(SETUP_STEP.CONNECTING_TO_HUB);
            device.addEventListener('gattserverdisconnected', onBluetoothDisconnect);
            const server = await device.gatt.connect();
            const service = await server.getPrimaryService(UUID.SERVICE);
            hubBluetoothConnection = new HubBluetoothConnection(device, server, service);
            setTimeout(() => {
                setHubConnected(true);
                setScanFailed(false);
                setSetupStep(nextSetupStep);
            }, 800);
        } catch (error) {
            if (error.message.includes('User cancelled')) {
                setHubConnected(false);
                setSetupStep(SETUP_STEP.INITIAL);
                setBluetoothCanceled(true);
                return;
            } else {
                console.error('Bluetooth connection failed');
                console.error(error);
                setScanFailed(true);
                setHubConnected(false);
                setSetupStep(SETUP_STEP.INITIAL);
            }
        }
    }

    function onBluetoothDisconnect() {
        setShowLostConnectionModal(true);
        hubBluetoothConnection.reset();
        setHubConnected(false); //??
    }

    return (
        <SetupContainer>
            <h1>Lets install Waved!</h1>
            {!hubConnected ? <>
                <h2>Make sure Waved Hub is powered and in proximity of this device</h2>
                <Button primary small onClick={(event) => startHubConnection(event, false)}>Find Waved Hub</Button>
                {scanFailed ? <>
                    <p style={{color: theme.colors.raspberryRed, margin: '5px'}}>Bluetooth is only accessable on computers and android phones while running Crome or Edge<br/></p>
                    <p style={{color: theme.colors.raspberryRed, margin: '5px'}}>Safari and IOS devices do not support Bluetooth through browsers</p>
                </>: null}
                {setupStep === SETUP_STEP.CONNECTING_TO_HUB ?
                    <SetupStatusContainer>
                        <IconLogo reveal />
                        <p>Connecting...</p>
                    </SetupStatusContainer>
                : null}
                {bluetoothCanceled ? <div style={{maxWidth: '500px', marginTop: '20px'}}>
                    <p style={{color: theme.colors.raspberryRed, textAlign: 'center'}}>
                        Problems finding the Waved Hub via bluetooth? <br></br><br></br>
                        Make sure that bluetooth is on and that the hub is powered and within close proximity to this device.
                    </p>
                </div>: null}
                <DocumentationLinkContainer>
                    <h4>For more information on the installation process see:</h4>
                    <a href="https://help.waved.co/en/collections/9476787-installation-partners" target="_blank" rel="noreferrer">Waved Installation Guide</a>
                </DocumentationLinkContainer>
            </>:<>

                {setupStep === SETUP_STEP.ETHERNET ? 
                    <EthernetSetup
                        hubBluetoothConnection={hubBluetoothConnection}
                        setSetupStep={setSetupStep}
                        SETUP_STEP={SETUP_STEP}
                        ethernet={ethernet}
                        setEthernet={setEthernet}
                        wifi={wifi}
                    />
                :null}

                {setupStep === SETUP_STEP.WIFI ? 
                    <WifiSetup
                        hubBluetoothConnection={hubBluetoothConnection}
                        setSetupStep={setSetupStep} SETUP_STEP={SETUP_STEP} 
                        wifi={wifi}
                        setWifi={setWifi}
                        internetEnsured={internetEnsured}
                    />
                :null}

                {setupStep === SETUP_STEP.CLIENT ? 
                    <ClientSetup
                        hubBluetoothConnection={hubBluetoothConnection}
                        setSetupStep={setSetupStep}
                        SETUP_STEP={SETUP_STEP}
                        setupNewClient={setupNewClient}
                        setSetupNewClient={setSetupNewClient}
                        client={client}
                        setClient={setClient}
                        ethernet={ethernet}
                    />
                :null}

                {setupStep === SETUP_STEP.INSTALLER ? 
                    <InstallerSetup
                        hubBluetoothConnection={hubBluetoothConnection}
                        setSetupStep={setSetupStep}
                        SETUP_STEP={SETUP_STEP}
                        ethernet={ethernet}
                    />
                :null}

                {setupStep === SETUP_STEP.USER ? 
                    <UserSetup
                        hubBluetoothConnection={hubBluetoothConnection}
                        setSetupStep={setSetupStep}
                        SETUP_STEP={SETUP_STEP}
                    />
                :null}

                {setupStep === SETUP_STEP.COMPLETE ? 
                    <HubSetupComplete
                        hubBluetoothConnection={hubBluetoothConnection}
                        setSetupStep={setSetupStep}
                        SETUP_STEP={SETUP_STEP}
                    />
                :null}

            </>}
            {hubConnected && setupStep===SETUP_STEP.CONNECTING_TO_HUB?
                <SetupStatusContainer>
                    <IconLogo reveal />
                    <p>Connecting...</p>
                </SetupStatusContainer>
            : null}
            <LostBtConnectionModal
                show={showLostConnectionModal}
                close={() => {setShowLostConnectionModal(false); setSetupStep(SETUP_STEP.INITIAL);}}
                reconnect={(event) => startHubConnection(event, true) }
            />
        </SetupContainer>
    );
}

export default NewInstalationView;



/*


async function scanForHubsEXAMPLE(event) {
    try{
        console.log('Starting Scan');
        const device = await navigator.bluetooth.requestDevice({
            filters: [
                {
                  namePrefix: "WavedHub",
                },
            ],
            optionalServices: [UUID.SERVICE]
        })
        console.log('device', device);
        const server = await device.gatt.connect();
        const service = await server.getPrimaryService(UUID.SERVICE);

        const characteristic_1 = await service.getCharacteristic(UUID.READ_AND_WRITE);
        console.log('characteristic', characteristic_1);
        const currentValueBuffer = await characteristic_1.readValue();
        const currentValue = new TextDecoder().decode(currentValueBuffer);
        console.log('currentValue', currentValue);
        const returnedOnWrite = await characteristic_1.writeValue(new TextEncoder().encode('Testing full characteristic'));
        console.log('returnedOnWrite', returnedOnWrite);
        const decodedReturnedOnWrite = new TextDecoder().decode(returnedOnWrite);
        console.log('decodedReturnedOnWrite', decodedReturnedOnWrite);

        const characteristic_2 = await service.getCharacteristic(UUID.READ);
        console.log('characteristic', characteristic_2);
        const unknownRerurnBuffer2 = await characteristic_2.readValue();
        console.log('unknownRerurn', unknownRerurnBuffer2);
        const unknownRerurnValue2 = new TextDecoder().decode(unknownRerurnBuffer2);
        console.log('unknownRerurnValue', unknownRerurnValue2);

        const characteristic_3 = await service.getCharacteristic(UUID.WRITE);
        console.log('characteristic', characteristic_3);
        const encodedString = new TextEncoder().encode('my test value');
        const unknownRerurnBuffer3 = await characteristic_3.writeValue(encodedString);
        console.log('unknownRerurn3', unknownRerurnBuffer3);
        const unknownRerurnValue3 = new TextDecoder().decode(unknownRerurnBuffer3);
        console.log('unknownRerurnValue3', unknownRerurnValue3);



    } catch (error) {
        if (error.message.includes('User cancelled')) return;
        else {
            console.error('Bluetooth scan failed');
            console.error(error);
            setScanFailed(true);
        }
    }
}
    */