import React, { useEffect, useState } from 'react';
import { useSubscription } from '../hooks/useSubscription';
import { subscriptionsTypes } from '../scripts/subscription/subscriptionTypes';
import Group from '../components/panel/groups/Group';
import styled from 'styled-components';
import theme from '../UI/theme';
import hierarchyTypes from '../components/settings/venue/device/hierarchyTypes';
import { getCustomer } from '../scripts/datasource';
import Footer from '../components/navigation/Footer';
import { updateElementInListState } from '../scripts/state/stateUtils';
import MusicPlayerList from '../components/panel/musicPlayer/MusicPlayerList';
import StartSetupLink from '../components/live/StartSetupLink';
import GoToSettingsLink from '../components/live/GoToSettingsLink';
import ViewHeader from '../components/UiComponents/ViewHeader';
import ReadOnlyInfo from '../components/permission/ReadOnlyInfo';
import { permissionTypes } from '../components/permission/permissions';
import ClientTroubleshooting from '../components/clientTroubleshooting.js/ClientTroubleshooting';

const PanelViewContainer = styled.div`
    width: fill-available;
    margin: auto;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 20px;
    padding-bottom: 20px;

    @media only screen and (min-width: ${theme.screenSizes.medium}px) {
        max-width: 70em;
    }

    @media only screen and (max-width: ${theme.screenSizes.medium - 1}px) {
        margin-bottom: 94px;
    }
`

const PanelView = props => {
    const [hubs, setHubs] = useSubscription(subscriptionsTypes.hubs);
    const [processors, setProcessors] = useSubscription(subscriptionsTypes.processors);
    const [schedules, setSchedules] = useSubscription(subscriptionsTypes.schedules);
    const [zones, setZones] = useSubscription(subscriptionsTypes.zones);
    const [zoneGroups, setZoneGroups] = useSubscription(subscriptionsTypes.zoneGroups);
    const [sourceSelectors, setSourceSelectors] = useSubscription(subscriptionsTypes.sourceSelectors);
    const [sources, setSources] = useSubscription(subscriptionsTypes.sources);
    const [musicPlayers, setMusicPlayers] = useSubscription(subscriptionsTypes.musicPlayers);
    const [customer, setCustomer] = useState();
    const [zoneGroupPreferences, setZoneGroupPreferences] = useState(JSON.parse(localStorage.getItem('zoneGroupPreferences')) ?? []);

    const showingSourceSelectors = zones?.filter(zone => zone.hide === 0)?.some(zone => sourceSelectors?.some(sourceSelector => sourceSelector?.sourceSelectorId === zone.sourceSelectorId));

    const sourceSelectorsWithoutZoneGroup = sourceSelectors?.filter(sourceSelector => !zoneGroups?.some(zoneGroup => zoneGroup?.zoneGroupId === sourceSelector?.zoneGroupId));
    const zonesWithoutZoneGroup = zones?.filter(zone => sourceSelectorsWithoutZoneGroup?.some(sourceSelector => sourceSelector?.sourceSelectorId === zone?.sourceSelectorId) ||
        (!zoneGroups?.some(zoneGroup => zoneGroup.zoneGroupId === zone?.zoneGroupId) && !sourceSelectors?.some(sourceSelector => sourceSelector.sourceSelectorId === zone?.sourceSelectorId)));
    const processorsWithoutZoneGroup = processors?.filter(processor => zonesWithoutZoneGroup?.some(zone => zone.processorId === processor?.processorId));
    const noZoneGroupId = `NONE_${props?.customerId}`;

    const unhiddenMasterZones = zones?.filter(zone => zone?.hierarchyType === hierarchyTypes.MASTER.value);
    const masterZoneProcessors = processors?.filter(processor => unhiddenMasterZones?.some(zone => zone?.processorId === processor?.processorId));
    const masterZoneGroupId = `MASTER_${props?.customerId}`;

    useEffect(() => {
        getData();
    }, [props.customerId]);

    const getData = async () => {
        let customerResult = await getCustomer(props.customerId);
        setCustomer(customerResult);
    }

    const setZone = (zoneId, updatedProps) => {
        updateElementInListState(zones, setZones, zoneId, updatedProps, 'zoneId');
    }
    const setSourceSelector = (sourceSelectorId, updatedProps) => {
        updateElementInListState(sourceSelectors, setSourceSelectors, sourceSelectorId, updatedProps, 'sourceSelectorId');
    }

    if (hubs?.length === 0) {
        return <PanelViewContainer>
            <StartSetupLink />
        </PanelViewContainer>
    } else if (zones?.length === 0) {
        return <PanelViewContainer>
            <GoToSettingsLink
                customerId={props.customerId}
                hub={hubs?.length === 1 ? hubs[0] : null}
            />
        </PanelViewContainer>
    }

    if (zones && sourceSelectors) {
        return <>
            <ReadOnlyInfo
                readPermissionType={permissionTypes.panelViewRead}
                writePermissionType={permissionTypes.panelViewWrite}
            />
            {(processors?.length && hubs?.length)? 
                <ClientTroubleshooting
                    processors={processors}
                    hubs={hubs}
                />
            :<></>}
            
            <ViewHeader headerText={`${customer?.companyName ?? 'My venue'}`} />
            <PanelViewContainer>
                {zoneGroups?.sort((a, b) => { return a?.orderIndex - b?.orderIndex })?.map((zoneGroup, index) => {
                    const sourceSelectorsInZoneGroup = sourceSelectors?.filter(sourceSelector => zoneGroup?.zoneGroupId === sourceSelector?.zoneGroupId);
                    const zonesInZoneGroup = zones?.filter(zone => sourceSelectorsInZoneGroup?.some(sourceSelector => sourceSelector?.sourceSelectorId === zone?.sourceSelectorId) ||
                        (zoneGroup?.zoneGroupId === zone?.zoneGroupId && !sourceSelectors?.some(sourceSelector => sourceSelector.sourceSelectorId === zone?.sourceSelectorId)));
                    const processorsInZoneGroup = processors?.filter(processor => zonesInZoneGroup?.some(zone => zone.processorId === processor?.processorId));

                    return <>
                        {sourceSelectorsInZoneGroup?.length > 0 || zonesInZoneGroup?.length > 0 ?
                            <Group
                                key={index}
                                zoneGroupId={zoneGroup?.zoneGroupId}
                                zoneGroup={zoneGroup}
                                zoneGroupPreferences={zoneGroupPreferences}
                                setZoneGroupPreferences={setZoneGroupPreferences}
                                customerId={props.customerId}
                                showingSourceSelectors={showingSourceSelectors}
                                sourceSelectors={sourceSelectorsInZoneGroup}
                                setSourceSelector={setSourceSelector}
                                zones={zonesInZoneGroup?.filter(zone => zone.hierarchyType !== hierarchyTypes.MASTER.value)}
                                setZone={setZone}
                                processors={processorsInZoneGroup}
                                sources={sources}
                                hubs={hubs}
                                schedules={schedules}
                            />
                            : <></>}
                    </>
                })}

                {sourceSelectorsWithoutZoneGroup?.length > 0 || zonesWithoutZoneGroup?.length > 0 ?
                    <Group
                        key={`zonesWithoutZoneGroup_${props?.customerId}`}
                        zoneGroupId={noZoneGroupId}
                        zoneGroupPreferences={zoneGroupPreferences}
                        setZoneGroupPreferences={setZoneGroupPreferences}
                        customerId={props.customerId}
                        showingSourceSelectors={showingSourceSelectors}
                        sourceSelectors={sourceSelectorsWithoutZoneGroup}
                        setSourceSelector={setSourceSelector}
                        zones={zonesWithoutZoneGroup.filter(zone => zone.hierarchyType !== hierarchyTypes.MASTER.value)}
                        setZone={setZone}
                        processors={processorsWithoutZoneGroup}
                        sources={sources}
                        hubs={hubs}
                        schedules={schedules}
                    /> : <></>}

                {unhiddenMasterZones?.length > 0 ?
                    <Group
                        key={`masterZoneGroup_${props?.customerId}`}
                        zoneGroupId={masterZoneGroupId}
                        zoneGroupName={"Master volumes"}
                        zoneGroupPreferences={zoneGroupPreferences}
                        setZoneGroupPreferences={setZoneGroupPreferences}
                        customerId={props.customerId}
                        showingSourceSelectors={showingSourceSelectors}
                        sourceSelectors={[]}
                        setSourceSelector={setSourceSelector}
                        zones={unhiddenMasterZones}
                        setZone={setZone}
                        processors={masterZoneProcessors}
                        sources={sources}
                        hubs={hubs}
                        schedules={schedules}
                    />
                    : <></>}

                {musicPlayers?.length > 0 ?
                    <MusicPlayerList musicPlayers={musicPlayers} />
                    : <></>}
                    
            </PanelViewContainer>
        </>
    } else {
        return <></>
    }
}

export default PanelView;