import React from 'react';
import styled from 'styled-components';
import IconLogo from '../../../UiComponents/IconLogo';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
`;


const FetchSpeakerAnimation = () => {
    return(
        <Container>
            <h4>Fetching speakers...</h4>
            <IconLogo preloader />
        </Container>
    )
};

export default FetchSpeakerAnimation;