import React from "react";
import styled from "styled-components";
import Button from "../../../UiComponents/Button";
import theme from "../../../../UI/theme";

const ShimmerButton = styled(Button)`
    background: linear-gradient(-45deg, ${theme.colors.darkSpace70} 25%, ${theme.colors.darkSpace50} 50%, ${theme.colors.darkSpace70} 75%);
    background-size: 400% 100%;
    animation: shimmer 1s infinite linear;

    @keyframes shimmer {

        0% {
            background-position: 0% 0;
        }

        100% {
            background-position: -300% 0;
        }

    }

`;

const ShimmerPrealoaderButton = ({ onClick, shimmerDuration, text, shimmerText}) => {
    const [shimmer, setShimmer] = React.useState(false);

    const onPress = () => {
        setShimmer(true);
        onClick();
        setTimeout(() => {
            setShimmer(false);
        }, shimmerDuration || 5000);
    }

    return (<>
        {shimmer? 
            <ShimmerButton secondary small >{shimmerText}</ShimmerButton>
        :
            <Button secondary small onClick={onPress}>{text}</Button>
        }
        
    </>)
}

export default ShimmerPrealoaderButton;