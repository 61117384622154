
import NameField from "../../fields/NameField";
import { SpeakersIcon } from "../../../UiComponents/Icons"
import { DisplayField } from "../../fields/Styles";
import ZoneChoiceField from "./fields/ZoneChoiseField";
import { getDefaultId } from "../../table/tableUtils";
import Button from "../../../UiComponents/Button";
import { actionTypes } from "../../utils/installationTypes";
import { fetchSpeakers, getSingleSpeakerZones, muteSpeakers, singleSpeakerSystems, unmuteSpeakers } from "./speakerUtils";
import { deleteSpeaker, updateSpeakers, updateAffectedZones } from "../../../../scripts/speaker";
import SpeakerMuteButton from "./fields/SpeakerMuteButton";
import AddressField from "../../fields/AddressField";
import SpeakerBlinkButton from "./fields/SpeakerBlinkButton";
import SpeakerPlaceholder from "./SpeakerPlaceholder";
import ShimmerPrealoaderButton from "./ShimmerPrealoaderButton";

const idField = 'speakerId';

export const getSpeakerTableProps = ({ customerId, hubs, processors, zones, speakers, setSelectedTab }) => {

    const singleSpeakerZones = [...getSingleSpeakerZones(zones, processors)];

    const getColumns = (speaker) => [{
            key: 'name',
            displayName: 'Name',
            form: true,
            width: '150px',
            render: (speaker, updateSpeaker, cellControl) => (
                <NameField
                    object={speaker}
                    updateObject={updateSpeaker}
                    objects={zones}
                    idField={idField}
                    nameField={'name'}
                    cellState={cellControl.cellState}
                />
            )
        },{
            key: 'type',
            displayName: 'Brand',
            width: '60px',
            readOnly: true,
            render: (speaker) => (
                <DisplayField>{speaker.type}</DisplayField>
            )
        },{
            key: 'speakerId',
            displayName: 'Id',
            width: '80px',
            readOnly: true,
            render: (speaker) => (
                <DisplayField>{speaker.speakerId}</DisplayField>
            )
        },{
            key: 'nativeId',
            displayName: 'Native Id',
            width: '80px',
            readOnly: true,
            render: (speaker) => (
                <AddressField
                    processorId={processors.find(p => p.type === speaker.type).processorId || processors[0].processorId}
                    hubId={processors.find(p => p.type === speaker.type).hubId || hubs[0].hubId}
                    type={'VOLUME'}
                    address={speaker.nativeId}
                    customerId={speaker.customerId}
                    setAddress={() => {console.log('trying to set address')}}
                    error={() => {console.log('Addressfield called props.error()')}}
                    //cellState={cellState}
                />
            )
        },{
            key: 'ip',
            displayName: 'Ip Address',
            width: '80px',
            readOnly: true,
            render: (speaker) => (
                <DisplayField>{speaker.ip}</DisplayField>
            )
        },{
            key: 'mac',
            displayName: 'Mac Address',
            width: '80px',
            readOnly: true,
            render: (speaker) => (
                <DisplayField>{speaker.mac}</DisplayField>
            )
        },{
            key: 'zoneId',
            displayName: 'Zone',
            width: '150px',
            form: true,
            getSuggested: () => getDefaultId(zones, 'zoneId'),
            render: (speaker, updateSpeaker, cellControl) => (
                <ZoneChoiceField
                    object={speaker}
                    zones={singleSpeakerZones}
                    processors={processors}
                    updateObject={updateSpeaker}
                    cellControl={cellControl}
                />
            )
        },{
            displayName: 'Mute',
            key: 'mute',
            width: '65px',
            render: (speaker) => (
                <SpeakerMuteButton
                    speaker={speaker}
                    hubs={hubs}
                /> 
            )
        },{
            displayName: 'Blink',
            key: 'blink',
            width: '65px',
            render: (speaker) => (
                <SpeakerBlinkButton
                    speaker={speaker}
                    hubs={hubs}
                /> 
            )
        }
];

    const saveObjects = async (changes) => {
        try {
            changes.forEach(change => {
                var speaker = speakers.find(speaker => speaker.speakerId === change.speakerId);
                change.nativeId = speaker.nativeId;
            });
            await updateAffectedZones(changes, singleSpeakerZones);
            await updateSpeakers(changes);
        } catch (err) {
            console.log('Failed to update speaker');
        }
    }

    const deleteObject = async (speaker) => {
        try {
            await deleteSpeaker(speaker.speakerId);
        } catch (err) {
            console.log('Error failed to delete source');
        }
    }

    const addObject = () => {}

    const getListItemProps = (speaker) => {
        return {
            displayName: speaker.name,
            Icon: SpeakersIcon
        }
    }

    const getPlaceholder = () => {
        return (
            <SpeakerPlaceholder
                customerId={customerId}
                hubs={hubs}
                processors={processors}
                speakers={speakers}
            />
        );
    }

    const getActions = (speaker) => [
        {
            key: 'fetchSpeakers',
            type: actionTypes.LIST,
            render: () => (
                <ShimmerPrealoaderButton
                    onClick={() => fetchSpeakers(customerId, hubs, processors)}
                    shimmerDuration={6000}
                    text={'Fetch speakers'}
                    shimmerText={'Fetching ...'}
                />
            )
        },{
            key: 'muteAllSpeakers',
            type: actionTypes.LIST,
            render: () => (
                <ShimmerPrealoaderButton
                    onClick={() => muteSpeakers(speakers, hubs)}
                    shimmerDuration={2000}
                    text={'Mute all speakers'}
                    shimmerText={'Muting ...'}
                />
                
            )
        },{
            key: 'unmuteAllSpeakers',
            type: actionTypes.LIST,
            render: () => (
                <ShimmerPrealoaderButton
                    onClick={() => unmuteSpeakers(speakers, hubs)}
                    shimmerDuration={2000}
                    text={'Unmute all speakers'}
                    shimmerText={'Unmuting ...'}
                />
            )
        },
    ];

    return {    
        title: 'Speakers',
        getColumns: getColumns,
        parentObjects: speakers,
        idField,
        saveObjects,
        deleteObject,
        //addObject,
        getListItemProps,
        getActions,
        getPlaceholder
    }
}
