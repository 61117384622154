import { Chart } from "chart.js";
import { useState, useEffect, useRef } from "react";
import theme from "../../../../UI/theme";
import 'chartjs-adapter-luxon';
import zoomPlugin from 'chartjs-plugin-zoom';
import { userInteractionTypes } from "../userInteractionTypes";
import { getDateTime } from "../../../../scripts/log";
import { chartAreaBackground } from "../../../statistics/charts/chartUtils";

Chart.register(zoomPlugin);

const chartData = {
    datasets: [
        {
            label: 'UI',
            data: [],
            pointRadius: 10,
            parsing: {
                yAxisKey: 'yValue'
            }
        }
    ]
}

const chartConfig = {
    type: 'scatter',
    data: chartData,
    options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            x: {
                type: 'time',
                time: {
                    tooltipFormat: 'EEE dd.MM T',
                    displayFormats: {
                        'millisecond': 'EEE dd.MM T',
                        'second': 'EEE dd.MM T',
                        'minute': 'EEE dd.MM T',
                        'hour': 'EEE dd.MM T',
                        'day': 'EEE dd.MM T',
                        'week': 'EEE dd.MM T',
                        'month': 'EEE dd.MM T',
                        'quarter': 'EEE dd.MM T',
                        'year': 'EEE dd.MM T',
                    }
                },
                ticks: {
                    maxTicksLimit: 7,
                    maxRotation: 20,
                },
                grid: {
                    color: theme.colors.darkSpace80
                }
            },
            y: {
                type: 'category',
                grid: {
                    color: theme.colors.darkSpace80
                }
            }
        },
        plugins: {
            legend: {
                display: false
            },
            tooltip: {
                callbacks: {
                    label: function (context) {
                        if (context.raw?.label) {
                            return [context.label, ...context.raw.label];
                        } else {
                            return context.label;
                        }
                    }
                }
            },
            zoom: {
                zoom: {
                    wheel: {
                        enabled: true,
                    },
                    pinch: {
                        enabled: true
                    },
                    mode: 'x'
                },
                pan: {
                    enabled: true,
                    mode: 'x'
                }
            },
            chartAreaBackground: {
                stops: []
            }
        }
    }
}

const UserInteractionsGraph = props => {
    const chartContainer = useRef(null);
    const [chartInstance, setChartInstance] = useState(null);

    chartConfig.options.scales.y.labels = ['Other'];
    chartConfig.data.datasets[0].data = [];
    chartConfig.data.datasets[0].backgroundColor = [];

    if (props.zones) {
        chartConfig.options.scales.y.labels = props.zones.map(zone => zone.zoneName);

        if (props.data && props.data.userInteractions) {
            props.data.userInteractions.map(ui => {
                var uiType = userInteractionTypes[Object.keys(userInteractionTypes).find(type => userInteractionTypes[type].key === ui.setting)];
                var labelArray = [`Type: ${uiType?.display ?? 'Other'}`];
                if (ui.fromValue || ui.toValue) {
                    labelArray.push(`Value: ${ui.fromValue} \u2192 ${ui.toValue}`);
                }
                chartConfig.data.datasets[0].data.push({
                    x: getDateTime(ui.time, props.timezone),
                    yValue: props.zones?.find(zone => zone.zoneId === ui.zoneId)?.zoneName ?? 'Other',
                    label: labelArray
                });
                chartConfig.data.datasets[0].backgroundColor.push(uiType?.color ?? 'white');
            })
        }
    }

    if (props.min && props.max) {
        chartConfig.options.plugins.zoom.limits = {
            x: {
                min: props.min,
                max: props.max
            }
        }

        chartConfig.options.scales.x.min = props.min;
        chartConfig.options.scales.x.max = props.max;
    }

    chartConfig.options.plugins.chartAreaBackground.stops = [];
    if (props.data.openingHours?.length) {
        props.data.openingHours.map((openingHour, index) => {
            chartConfig.options.plugins.chartAreaBackground.stops.push({
                x: props.data.openingHours[index].milliseconds,
                color: openingHour.open ? theme.colors.darkSpace70 : theme.colors.greenEnergy
            });
            chartConfig.options.plugins.chartAreaBackground.stops.push({
                x: openingHour.milliseconds,
                color: openingHour.open ? theme.colors.greenEnergy : theme.colors.darkSpace70
            });
        })
    }

    if (chartInstance) {
        chartInstance.update();
        chartInstance.resetZoom();
    }

    useEffect(() => {
        if (chartContainer && chartContainer.current) {
            if (chartInstance) {
                chartInstance.destroy();
            }
            const newChartInstance = new Chart(chartContainer.current, { ...chartConfig, plugins: [{ ...chartAreaBackground, id: 'chartAreaBackground' }] });
            newChartInstance.resetZoom();
            setChartInstance(newChartInstance);
        }
    }, [chartContainer]);

    return <canvas ref={chartContainer} style={{ height: '100%', width: '100%', maxWidth: '100%' }} ></canvas>
}

export default UserInteractionsGraph;