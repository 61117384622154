import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import WaInput from '../UiComponents/Icons/WaInput';
import PanelButton from './PanelButton';
import SourceSelectorSkeleton from './skeletons/SourceSelectorSkeleton';
import SourceSelectorModal from './SourceSelectorModal';

const SourceSelectorButton = styled(PanelButton)`
    height: 100%;
    justify-content: center;
    grid-column: 1;
    grid-row: ${props => `1 / span ${props.rowSpan}`};
`

const SourceSelector = props => {

    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        if (props.showSourceSelectorId === props.sourceSelector.sourceSelectorId) {
            setShowModal(true);
        }
    }, [props.showSourceSelectorId])

    const setActiveSource = (sourceId) => {
        props.setSourceSelector(props.sourceSelector.sourceSelectorId, { activeSourceId: sourceId });
    }

    const showModalHandler = (state) => {
        if (!state) {
            props.setShowSourceSelectorId(null);
        }
        setShowModal(state);
    }

    return <>
        {props.sourceSelector ? <>
            <SourceSelectorButton
                rowSpan={props.rowSpan ?? 1}
                row={props.row}
                onClick={() => showModalHandler(true)}
                disabled={props.disabled}>
                <WaInput />
                <label>Source</label>
            </SourceSelectorButton>

            <SourceSelectorModal
                show={showModal} // props.showSourceSelectorModal
                close={() => { showModalHandler(false); }}
                sourceSelector={props.sourceSelector}
                setActiveSource={setActiveSource}
                processor={props.processor}
                sources={props.sources}
                processorConnected={props.processorConnected}
            />
        </>
            : <SourceSelectorSkeleton
                row={props.row}
                rowSpan={props.rowSpan ?? 1} />}
    </>

}

export default SourceSelector;