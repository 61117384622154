import { useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router';
import styled from 'styled-components';
import { getDateTime } from '../../../scripts/log';
import theme from '../../../UI/theme';
import Modal from '../../UiComponents/Modal';
import { CustomerDetailsTable } from './customerDetailsStyles';
import UITableIndicator from './UITableIndicator';
import { userInteractionTypes } from './userInteractionTypes';

const TableContainer = styled.div`
    max-height: 100%;
    min-width: fit-content;

    @media only screen and (max-width: ${theme.screenSizes.large - 1}px) {
        min-width: 100%;
    }

    overflow: auto;
`

const ZoneLink = styled.label`
    &:hover {
        text-decoration: underline;
        cursor: pointer;
    }
`

const ModalHeader = styled.h3`
    margin: 0 0 10px 0;
`

const ModalText = styled.div`
    line-height: 100%;
    margin: 0;
    padding: 0 10px;
    max-height: 70vh;
    overflow: auto;
    border-radius: 4px;
    background-color: ${theme.colors.darkSpace80};
`

const UserInterationsTable = props => {
    const match = useRouteMatch();
    const history = useHistory();
    const [showUiModal, setShowUiModal] = useState(false);
    const [selectedUI, setSelectedUI] = useState();

    const goToZoneDetailsView = (zoneId) => {
        history.push(`${match.url}/${zoneId}`);
        window.scrollTo(0, 0);
    }

    return <>
        <TableContainer>
            <CustomerDetailsTable>
                <thead>
                    <tr>
                        <td></td>
                        <td>Time</td>
                        <td>Zone</td>
                        <td>Setting</td>
                        <td>From</td>
                        <td>To</td>
                    </tr>
                </thead>

                <tbody>
                    {props.userInteractions?.map((userInteraction, index) => {
                        var openModal = () => {
                            setShowUiModal(true);
                            setSelectedUI(userInteraction)
                        };

                        return <tr key={index}>
                            <td><UITableIndicator
                                color={userInteractionTypes[Object.keys(userInteractionTypes).find(key => userInteractionTypes[key].key === userInteraction.setting)]?.color}
                                clickHandler={openModal} />
                            </td>
                            <td>{getDateTime(userInteraction.time, props.timezone).toLocaleString()}</td>
                            <td><ZoneLink onClick={() => goToZoneDetailsView(userInteraction.zoneId)}>{props.zones?.find(zone => zone.zoneId === userInteraction.zoneId)?.zoneName}</ZoneLink></td>
                            <td>{userInteractionTypes[Object.keys(userInteractionTypes).find(type => userInteractionTypes[type].key === userInteraction.setting)]?.display}</td>
                            <td>{userInteraction.fromValue}</td>
                            <td>{userInteraction.toValue}</td>
                        </tr>
                    })}
                </tbody>
            </CustomerDetailsTable>
        </TableContainer>

        <Modal show={showUiModal} close={() => { setShowUiModal(false); setSelectedUI(null); }}>
            <ModalHeader>Raw UI</ModalHeader>
            <ModalText>
                <pre>
                    {JSON.stringify(selectedUI, null, 2)}
                </pre>
            </ModalText>
        </Modal>
    </>
}

export default UserInterationsTable;