import { Switch, Route, useRouteMatch } from "react-router-dom";
import SettingsMain from '../components/settings/SettingsMain';
import VenueSettings from '../components/settings/venue/VenueSettings';
import UserSettings from '../components/settings/user/UserSettings';
import ScheduleView from '../components/settings/venue/schedule/ScheduleView';
import MusicContentView from '../components/musicPlayer/MusicContentView';
import ZoneGroupView from '../components/settings/venue/group/ZoneGroupView';
import CalibrationView from './CalibrationView';
import ZoneSettingsView from '../components/settings/venue/zone/ZoneSettingsView';
import ZoneSettings from '../components/settings/venue/zone/ZoneSettings';
import LiveCalibration from '../components/settings/venue/calibration/live/LiveCalibration';
import ZoneLiveCalibrationView from '../components/settings/venue/calibration/live/ZoneLiveCalibrationView';
import LocalAppView from '../components/settings/venue/localApp/LocalAppView';
import EmailChangeView from './EmailChangeView';
import UserVenuesView from "../components/settings/user/userVenues/UserVenuesView";
import PermissionChecker from "../components/permission/PermissionChecker";
import { permissionTypes } from "../components/permission/permissions";

const SettingsView = props => {
    let match = useRouteMatch();

    return <Switch>
        <Route path={`${match.path}/user/email`}>
            <EmailChangeView />
        </Route>
        <Route path={`${match.path}/user`}>
            <UserSettings />
        </Route>
        <Route path={`${match.path}/uservenues`}>
            <UserVenuesView />
        </Route>
        <Route path={`${match.path}/venue`}>
            <PermissionChecker permissionType={permissionTypes.settingsRead}>
                <VenueSettings customerId={props.customerId} />
            </PermissionChecker>
        </Route>
        <Route path={`${match.path}/schedules`}>
            <PermissionChecker permissionType={permissionTypes.settingsRead}>
                <ScheduleView customerId={props.customerId} />
            </PermissionChecker>
        </Route>
        <Route path={`${match.path}/groups`}>
            <PermissionChecker permissionType={permissionTypes.settingsRead}>
                <ZoneGroupView customerId={props.customerId} />
            </PermissionChecker>
        </Route>
        <Route path={`${match.path}/calibration`}>
            <PermissionChecker permissionType={permissionTypes.installationViewRead}>
                <CalibrationView customerId={props.customerId} />
            </PermissionChecker>
        </Route>
        <Route path={`${match.path}/livecalibration/:zoneId`}>
            <PermissionChecker permissionType={permissionTypes.installationViewRead}>
                <ZoneLiveCalibrationView customerId={props.customerId} />
            </PermissionChecker>
        </Route>
        <Route path={`${match.path}/livecalibration`}>
            <PermissionChecker permissionType={permissionTypes.installationViewRead}>
                <LiveCalibration customerId={props.customerId} />
            </PermissionChecker>
        </Route>
        <Route path={`${match.path}/musicplayers`}>
            <PermissionChecker permissionType={permissionTypes.settingsRead}>
                <MusicContentView customerId={props.customerId} />
            </PermissionChecker>
        </Route>
        <Route path={`${match.path}/zones/:zoneId`}>
            <PermissionChecker permissionType={permissionTypes.settingsRead}>
                <ZoneSettings customerId={props.customerId} />
            </PermissionChecker>
        </Route>
        <Route path={`${match.path}/zones`}>
            <PermissionChecker permissionType={permissionTypes.settingsRead}>
                <ZoneSettingsView customerId={props.customerId} />
            </PermissionChecker>
        </Route>
        <Route path={`${match.path}/localApp`}>
            <LocalAppView customerId={props.customerId} />
        </Route>

        <Route path={`${match.path}`}>
            <SettingsMain />
        </Route>
    </Switch>
}

export default SettingsView;